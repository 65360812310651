.overview-price {
  padding: 8px;
}
.overview-price .total {
  font-size: 1em;
}
.overview-price .price {
  margin: 4px 0;
  font-size: 1.6em;
}
.overview-price .people {
  font-size: 0.8em;
}
.q-item-sublabel {
  text-align: center;
  margin-bottom: 15px;
}
.times {
  padding: 30px;
  padding-top: 0;
  justify-content: center;
  display: flex;
}
.times button:first-of-type {
  margin-right: 15px;
}
.time-button {
  width: 86px;
}
/*# sourceMappingURL=src/pages/ondemand/tram/sidebar/index.css.map */