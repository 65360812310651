
















































.location-info
  margin-bottom 8px
.location-info .destination
  font-size 20px

.leg-name
  text-transform uppercase
  font-weight 700
  font-size 15px

.leg-date
  color black
  font-size 13px

.times
  font-size 20px

.arrow
  vertical-align bottom

.leg-info
  font-size 13px

